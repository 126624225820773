import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/article-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Not a typical day in the woods: Local hunting guide, Kevin Overfield has a run-in with a rattlesnake`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "670px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/52d373de96e120b20395c57d693a55cb/63a7e/rattlesnake-kevin.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAFvaeQvkol//8QAHBAAAwABBQAAAAAAAAAAAAAAAAECEgMREyEi/9oACAEBAAEFAleRb2F6OXrVeVq0j//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AVf/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARL/2gAIAQIBAT8BsZf/xAAdEAACAgEFAAAAAAAAAAAAAAAAEQECEiExMlFh/9oACAEBAAY/AlSyn0l7wMoqomdcejgf/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAMSFBcf/aAAgBAQABPyHmCcvBJeHZ81GDz5moCI3Og0Sl7gpD+u//2gAMAwEAAgADAAAAEJg//8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EE6Uf//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEVH/2gAIAQIBAT8QEMLPb//EABwQAQADAQEAAwAAAAAAAAAAAAERITEAQVHB0f/aAAgBAQABPxBgOqqfOWPneyUYGYaxZNnXOVoKjfvgq/QZs/eIGUMCF7E7zbTmUnv/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rattlesnake kevin",
            "title": "rattlesnake kevin",
            "src": "/static/52d373de96e120b20395c57d693a55cb/63a7e/rattlesnake-kevin.jpg",
            "srcSet": ["/static/52d373de96e120b20395c57d693a55cb/e07e9/rattlesnake-kevin.jpg 200w", "/static/52d373de96e120b20395c57d693a55cb/066f9/rattlesnake-kevin.jpg 400w", "/static/52d373de96e120b20395c57d693a55cb/63a7e/rattlesnake-kevin.jpg 670w"],
            "sizes": "(max-width: 670px) 100vw, 670px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You’ve been bitten by a rattlesnake, you’re alone and you’re one and a half miles from your vehicle. What would you do?
Kevin Overfield, owner of Juniper Creek Outdoors in Williams ran into this exact scenario Oct. 19 when he was bitten by a rattlesnake while guiding an elk hunt in Unit 10 along Highway 64. Luckily, Overfield has spent most of his life in the woods, hunting and guiding hunts throughout Arizona; he remained calm, hiked back to his vehicle and then drove himself to the hospital.
In 2017, Overfield opened Juniper Creek Outdoors, which caters to fishermen, hunters, campers and hikers. They also provide guided hunts in northern Arizona.
On Oct. 18, a hunter Overfield was guiding, had shot an elk but had been unsuccessful in locating the animal. They decided to return first thing the following morning to retrieve the elk.`}</p>
    <p>{`In the early morning hours of Oct. 19, the temperature was around 16 degrees.`}</p>
    <p>{`“We got out there and started trailing (the elk) and could see where it had laid down. So we got way out there and I thought, ‘Well, there’s no reason for my hunters to walk back,’ so I said, ‘I’ll walk back to the truck and I’ll come back and meet you,” Overfield said.`}</p>
    <p>{`By 9:45 a.m. the temperature had risen to between 55-60 degrees. With increased temperatures and no obvious area for snakes to be hidden, Overfield said he was not looking for snakes.`}</p>
    <p>{`“It was 16 degrees that morning, I wasn’t looking for a snake,” he said. “I was walking back and going up the canyon and it never rattled or anything, he just hit me.”`}</p>
    <p>{`Overfield said the snake was tucked under a shelf in a rock outcropping.`}</p>
    <p>{`Overfield was bit on the outside of his right calf, several inches below the kneecap.`}</p>
    <p>{`“It felt like I had bumped my leg into a cactus,” he said. “But I knew I had been bit, so I jumped onto a ledge above me and I could see the side of the snake going under.”
Overfield had about one and a half miles to hike back to his pick-up truck and he was alone, having left the hunters to retrieve the truck. Knowing that venom travels faster when a person is excited or overexerts themselves, Overfield said he did not hurry.
“I walked back slow,” he said. “My grandpa, he used to always tell me when I was a kid, ‘if you got tobacco, rub tobacco on it.’ So I did, I rubbed Copenhagen on it.”`}</p>
    <p>{`Overfield said during his hike back, he did his best to self-monitor his vitals.`}</p>
    <p>{`“I was scared,” he said. “I wasn’t afraid of dying, I was afraid of losing that muscle on the back of my leg — cause that’s what we do (hike and guide hunts).”`}</p>
    <p>{`Once he reached his vehicle, Overfield called his wife and drove back two and a half miles to where he had left the hunters. He picked up the hunters before heading to Flagstaff.`}</p>
    <p>{`“Once I hit the highway, it was never under 100 (mph),” he said.`}</p>
    <p>{`Overfield made it to the hospital around 11:20 a.m., just over an hour and a half after he had been bit. He said the only thing he noticed while en-route was that his palms felt numb. He was later told this reaction was due to an increase in his blood pressure.`}</p>
    <p>{`Once Overfield arrived at Flagstaff Medical Center, the bite mark was circled and stamped with the time of his arrival.`}</p>
    <p>{`“About 30 minutes later (the doctor) walked in and said, ‘I think you have a dry bite,’” Overfield said. “I guess they say 35 percent of large snakes produce a dry bite.”`}</p>
    <p>{`Overfield asked the doctor if there was anything he would need for infection, but was told no, not unless he became sick.`}</p>
    <p>{`Just over three weeks since he was bit, Overfield said the bite marks still have not healed and he has noticed some lingering side effects.`}</p>
    <p>{`“My knee,” he said. “And I’ve got some head stuff going on.”`}</p>
    <p>{`Overfield said his knee is swollen and the back tendons on his knee feel like they won’t stretch out. He has also had consistent, severe headaches.`}</p>
    <p>{`Having been released from the hospital, Overfield returned to work the next day.`}</p>
    <p>{`Looking back, Overfield said he would not have done anything differently.`}</p>
    <p>{`“When you’re hunting I don’t want people looking at the ground, I want them to be successful,” he said. “I wasn’t in heavy brush, I wasn’t in a big rock pile and heavy brush — it was open.”`}</p>
    <p>{`Scientists have identified 36 species of rattlesnake and Arizona is home to 13 of them, according to Arizona Game and Fish Department. All species are venomous with the venom made up of complex poisons. In addition to rattles, all rattlesnakes have a triangular shaped head, cat-like pupils and foldable fangs.`}</p>
    <p>{`How bad is it if I get bitten?`}</p>
    <p>{`Scientists agree that rattlesnakes are defensive and the rattle of the snake’s tail is a warning sign.`}</p>
    <p>{`The Arizona Black Rattlesnake is docile compared to other rattlesnakes in Arizona. Western Diamond-back Rattlesnakes are responsible for more bites and deaths than any other rattlesnake species. However, the venom of Arizona Black Rattlesnakes is 2 to 2.5 times the toxicity of the Western Diamond-backed Rattlesnake. The Mojave Rattlesnake is widely considered the most toxic rattlesnake in the United States. Any bite from one of these snakes should be considered life-threatening.`}</p>
    <p>{`The Arizona Black Rattlesnake is the most common rattlesnake in the higher elevations of northern Arizona.`}</p>
    <p>{`Although data on specific bites from rattlesnakes is elusive, in 2018, the Center for Disease Control estimated that there were around 8,000 venomous snakebites each year in the U.S. — these typically result in about five deaths.`}</p>
    <p>{`Most of these fatal bites are attributed to the eastern and western diamondback rattlesnake. Copperheads account for more cases of venomous snake bites than any other North American species; however, their venom is the least toxic, so their bite is seldom fatal.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "715px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b05c4e95d6b0d5797e417020d5bacbc1/d33ce/rattlesnake.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAgP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABZYNIgLFf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEDEQISE//aAAgBAQABBQKVybdZMhXQlSP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAABBAIDAAAAAAAAAAAAAAABABARIQIxImGR/9oACAEBAAY/AuM+bQrIHpXtoDf/xAAaEAEAAgMBAAAAAAAAAAAAAAABESEAEEGB/9oACAEBAAE/Ia+ICongiqDmnKvpoiCA1//aAAwDAQACAAMAAAAQ5A//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCn/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qh//EABoQAQEBAQEBAQAAAAAAAAAAAAERMQBBECH/2gAIAQEAAT8QGp4Zivauc2pASCt1uHYMIPxl5LvD2FAPPn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rattlesnake",
            "title": "rattlesnake",
            "src": "/static/b05c4e95d6b0d5797e417020d5bacbc1/d33ce/rattlesnake.jpg",
            "srcSet": ["/static/b05c4e95d6b0d5797e417020d5bacbc1/e07e9/rattlesnake.jpg 200w", "/static/b05c4e95d6b0d5797e417020d5bacbc1/066f9/rattlesnake.jpg 400w", "/static/b05c4e95d6b0d5797e417020d5bacbc1/d33ce/rattlesnake.jpg 715w"],
            "sizes": "(max-width: 715px) 100vw, 715px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Snake facts:`}</h2>
    <p>{`7- 8,000 snake bites annually in the U.S., but only one in 50 million people will die from snakebites, meaning there are approximately 5-6 fatalities in the U.S.`}</p>
    <p>{`20-25 percent of all pit viper bites and 50 percent of Coral Snake bites are dry bites, however, all venomous snakes could deliver dry bites.`}</p>
    <p>{`13 species of rattlesnake live in Arizona, more than any other state.`}</p>
    <p>{`You are nine times more likely to die from being struck by lightning than to die of venomous snakebite.`}</p>
    <p>{`Information provided by Arizona Game and Fish, CDC and Department of Wildlife Ecology and Conservation`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      